import {
  FiatPrice,
  FlightFare,
  HotelBookErrorType,
  IIdExperiences,
  IIdFlight,
  IIdLodgings,
  Maybe,
  OpaqueProperties,
  PaymentErrorEnum,
  TripDetails,
} from "..";
import { DriverLicense, IFlightGridFares, TripSummary } from "../apis";

export enum CallState {
  NotCalled,
  InProcess,
  Success,
  Failed,
}

export enum TagColors {
  GREEN = "green",
  GREY = "grey",
  RED = "red",
  YELLOW = "yellow",
}

export interface TagInfo {
  label: string;
  type: TagColors;
  tooltipCopy?: string;
  iconName?: string;
  onClick?: () => void;
}

export interface ICoordinates {
  lat: Double;
  lon: Double;
}

export type Double = number;

export type Integer = number;

export type CountryCode = string;

export type UserId = Uuid;

export type Uuid = string;

export type Float = number;

export type Date = string;
export type DateTime = string;

export type Timestamp = number;

export type JsObject = {
  [key: string]: any;
};

// Representing Hopper custom HttpHelper URI object.
export type Uri = string;

// Representing Java URL object.
export type Url = string;

export type CardType = CardTypeConstants | string;

export enum CardTypeConstants {
  Visa = "visa",
  Master = "master",
  AmericanExpress = "american_express",
  DinersClub = "diners_club",
  Discover = "discover",
  Jcb = "jcb",
  Dankort = "dankort",
  Maestro = "maestro",
}

export const CardTypeCollection: { [key in CardType]: string } = {
  [CardTypeConstants.Visa]: "Visa",
  [CardTypeConstants.Master]: "Master",
  [CardTypeConstants.AmericanExpress]: "American Express",
  [CardTypeConstants.DinersClub]: "Diners Club",
  [CardTypeConstants.Discover]: "Discover",
  [CardTypeConstants.Jcb]: "Jcb",
  [CardTypeConstants.Dankort]: "Dankort",
  [CardTypeConstants.Maestro]: "Maestro",
};

export enum Currency {
  CAD = "CAD",
  USD = "USD",
  PEN = "PEN",
  EUR = "EUR",
  GBP = "GBP",
  AUD = "AUD",
  MXN = "MXN",
  NZD = "NZD",
  CNY = "CNY",
  JPY = "JPY",
  INR = "INR",
  COP = "COP",
  CLP = "CLP",
}

export interface TokenizeCardErrors {
  key: string;
  message: string;
  status: number;
  attribute: string;
}

export enum PassengerTypes {
  Adult = "ADT",
  InfantInSeat = "INS",
  InfantInLap = "INF",
  Child = "CNN",
}

export interface CardValidationErrors {
  cardType: CardType;
  validNumber: boolean;
  validCvv: boolean;
  numberLength: number;
  cvvLength: number;
}

export interface AirportRegion {
  regionType: RegionType;
  code: string;
}

export enum RegionType {
  Airport = "airport",
  City = "city",
}

export interface IPassenger {
  person: IPerson;
  lapInfant: boolean;
}

export interface IProxyPassenger {
  travellerId: PersonId;
  lapInfant: boolean;
}

export interface IPerson {
  id: PersonId;
  lastBookedWith?: DateTime;
  givenName: string;
  middleName?: string;
  surname: string;
  gender: Gender;
  dateOfBirth: LocalDate;
  phoneNumber?: string;
  emailAddress?: string;
  redressNumber?: string;
  knownTravelerNumber?: string;
  assistance: Assistance[];
  createdAt: DateTime;
  nationality?: INationality;
  passport?: IPassport;
  frequentFlyer: FrequentFlyerMap;
  driverLicense?: DriverLicense;
  hotelLoyalty: HotelLoyaltyMap;
}

export interface ICorpPerson extends IPerson {
  isGhostUser?: boolean;
  isMissingInfo?: boolean;
  linkedDEUserId?: string;
  isPrivateProfile?: boolean;
  isDefaultProfile?: boolean;
}

export interface ICorpPersonWithNameNumber extends ICorpPerson {
  nameNumber?: string;
}

export type CorpPortalPermissions =
  | "canViewAdmin"
  | "canBookTravel"
  | "canViewTravel"
  | "canApproveBooking"
  | "canViewUsers"
  | "canEditUsers"
  | "canViewAllTravel"
  | "canViewPolicy"
  | "canEditPolicy"
  | "canViewReport"
  | "canEditBusiness";

export enum ModalNames {
  HAS_SEEN_ONBOARDING_MODAL = "onboarding_modal",
  SEAN_ELLIS_MODAL = "sean_ellis",
  VXB_HELPTIP = "vxb_helptip",
  NFU_IN_PRODUCT_AWARENESS = "nfu_in_product_awareness",
}

export type ModalName = `${ModalNames}`;
export const notSeenModals = {
  sean_ellis: false,
  vxb_helptip: false,
  onboarding_modal: false,
  nfu_in_product_awareness: false,
};

export type UserCorpPermissions = Record<CorpPortalPermissions, boolean>;

export interface SessionInfo {
  csrfToken: string;
  sessionExpiration: DateTime;
  userInfo: UserInfo;
  isFirstSession: boolean;
  isDelegatedSession?: string;
  redirectTo?: string;
  userId: string;
}

export interface CorporateInfo {
  permissions: UserCorpPermissions;
  role: string[];
  policyTier: string;
  businessId?: string;
  businessName?: string;
  businessIsLive?: boolean;
  businessCreatedAt?: string;
  accountReferenceId?: string;
  businessLoyaltyPrograms?: string[];
  defaultTravelerId: string;
  hiddenTravelerIds: string[];
  hasSeenModalMap: Record<ModalName, boolean> | undefined;
  cap1Role: string;
}

export interface CorpSessionInfo extends SessionInfo {
  corporateInfo: CorporateInfo;
}

export interface UserInfo {
  firstName: string;
  lastName: string;
  email: string;
}

export type FrequentFlyerMap = {
  [key in AirlineCode]: { value: FrequentFlyerNumber };
};

export type HotelLoyaltyMap = {
  [key: string]: HotelLoyaltyNumber;
};

export type PersonId = Uuid;

export enum Gender {
  M = "M",
  F = "F",
}

export type LocalDate = string;

export enum Assistance {
  BLND = "BLND", // blind passenger
  DEAF = "DEAF", // deaf passenger
  STCR = "STCR", // stretcher assistance
  WCHR = "WCHR", // Wheelchair/Passenger can walk up stairs
  WCHS = "WCHS", // Wheelchair/Passenger can walk to seat
  WCHC = "WCHC", // Wheelchair/Passenger must be carried
  WCBD = "WCBD", // Wheelchair with Dry Cell Battery
  WCBW = "WCBW", // Wheelchair with Wet Cell Battery
  WCLB = "WCLB", // Wheelchair with Lithium Battery
  WCMP = "WCMP", // Wheelchair, manual power
  WCOB = "WCOB", // Wheelchair, On-Board
}

export interface INationality {
  country: CountryCode;
}

export interface IPassport {
  countryOfIssue: CountryCode;
  number: string;
  expiration: LocalDate;
}

export type AirlineCode = string;

export type AirportCode = string;

export type FlightNumber = number;

export type FrequentFlyerProgramCode = string;

export type FrequentFlyerNumber = string;

export type FareId = string;

export type OpaqueFormat = string;

export type LocalDateTime = string;

export type AirlineLocator = string;

export type Duration = string;

export type HotelLoyaltyProgramCode = string;

export interface HotelLoyaltyNumber {
  value: string;
}

export interface ISeatedPassengers {
  withLapInfants: IAdultWithLapInfant[];
  alone: IAssociatedPassenger[];
  contact: IAssociatedContact;
}

export interface IAssociatedContact {
  nameNumber: NameNumber;
  contact: IContact;
}

export interface IContact {
  phoneNumber: string;
  emailAddress: string;
}

export interface IAlertOptInCore {
  disabled?: boolean;
  isMobile?: boolean;
}

export interface IAlertOptIn extends IAlertOptInCore {
  appNotifBeenSelected?: boolean;
  smsBeenSelected?: boolean;
  hasSelectedDisruption?: boolean;
  toggleAppNotifOptIn?: () => void;
  toggleSMSOptIn?: () => void;
  disruptionOptInEnabled?: boolean;
  nonFDAdisruptionOptInEnabled?: boolean;
  tcpaConsentPhoneNumber?: string;
  disruptionDelayThresholdStringInHours?: string;
}

export interface IAdultWithLapInfant {
  adult: IAssociatedPassenger;
  infant: IAssociatedPassenger;
}

export interface IAssociatedPassenger {
  nameNumber: NameNumber;
  type: PassengerType;
  person: IPerson;
}

export type PassengerType = string;

export enum PassengerTypeEnum {
  Adult = "Adult",
  Child = "Child",
  LapInfant = "LapInfant",
  SeatedInfant = "SeatedInfant",
}

export type NameNumber = string;

export type BigDecimal = number;

export type BookingSessionId = Uuid;

export type AuthServiceId = Uuid;

export interface ITokenInfo {
  createdAt: DateTime;
  sessionId: TokenInfoId;
  deviceId: DeviceId;
  ipAddress?: string;
}

export type TokenInfoId = string;

export type DeviceId = string;

export const B2B_PORTAL_JWT_SECRET_KEY = "b2bportal-jwt-secret";

export const B2B_PORTAL_AUTH_REDIRECT_TO = "b2bportal-auth-redirectTo";

export const B2B_PORTAL_AUTH_INVALID_SESSION = "b2bportal-auth-invalid-session";

export const B2B_PORTAL_CSRF_TOKEN = "H-Csrf-Token";

export const B2B_PORTAL_BANNER_CONTAINER_ID = "b2bportal-banner-container";

export const B2B_PORTAL_STARTSESSION_PATH = "/auth/startsession/";

export const B2B_PORTAL_UNAUTHORIZED_PATH = "/auth/invalidsession/";

export const B2B_PORTAL_ENDSESSION_PATH = "/auth/endsession/";

export interface Location {
  code: string;
  name?: string;
}

export interface IPriceRange {
  min: number;
  max: number;
}

export enum BaggageInfoEnum {
  Allowed = "Allowed",
  NotAllowed = "NotAllowed",
  Unknown = "Unknown",
}

export enum PenaltiesInfoEnum {
  ExternallyManaged = "ExternallyManaged",
  Known = "Known",
  Unknown = "Unknown",
}

export interface ILocationDescriptorBase {
  LocationDescriptor: LocationDescriptorEnum;
}

export enum LocationDescriptorEnum {
  BoundingBox = "BoundingBox",
  BoundingCircle = "BoundingCircle",
}

export interface Coordinates {
  lat: number;
  lon: number;
}

export interface BoundingBox extends ILocationDescriptorBase {
  northEast: Coordinates;
  southWest: Coordinates;
  LocationDescriptor: LocationDescriptorEnum.BoundingBox;
}

export interface BoundingCircle extends ILocationDescriptorBase {
  center: Coordinates;
  radius: number;
  LocationDescriptor: LocationDescriptorEnum.BoundingCircle;
}

export type LocationDescriptor = BoundingBox | BoundingCircle;

export type LocationQuery =
  | ILocationQueryLabel
  | ILocationQueryCoordinate
  | ILocationQueryLodgingFeaturedMarkets
  | ILocationQueryEmpty;

interface ILocationQueryBase {
  LocationQuery: LocationQueryEnum;
}

export enum LocationQueryEnum {
  Label = "Label",
  Coordinate = "Coordinate",
  LodgingFeaturedMarkets = "LodgingFeaturedMarkets",
  Empty = "Empty",
}

export interface ILocationQueryLabel extends ILocationQueryBase {
  l: string;
  LocationQuery: LocationQueryEnum.Label;
}

export interface ILocationQueryCoordinate extends ILocationQueryBase {
  lat: Double;
  lon: Double;
  LocationQuery: LocationQueryEnum.Coordinate;
}

export interface ILocationQueryLodgingFeaturedMarkets
  extends ILocationQueryBase {
  LocationQuery: LocationQueryEnum.LodgingFeaturedMarkets;
}

export interface ILocationQueryEmpty extends ILocationQueryBase {
  LocationQuery: LocationQueryEnum.Empty;
}

export interface IResponse {
  categories: ICategorizedResponse[];
}

export interface ICategorizedResponse {
  category: Category;
  label: string;
  results: IResult[];
}

export type Id = IIdFlight | IIdLodgings | IIdExperiences;

export interface IResult {
  id: Id;
  label: string;
  opaqueProperties?: OpaqueProperties;
  subLabel?: string;
  trackingPropertiesV2?: TrackingProperties;
}

export enum Category {
  Airports = "Airports",
  Cities = "Cities",
  HotelMarkets = "HotelMarkets",
  Places = "Places",
  RecentSearches = "RecentSearches",
  EmptyMatch = "EmptyMatch",
  Categories = "Categories",
  Experiences = "Experiences",
}

export interface IIdBase {
  Id: IdEnum;
}

export enum IdEnum {
  Flight = "Flight",
  HotelMarket = "HotelMarket",
  Lodgings = "Lodgings",
  Grounds = "Grounds",
  Experiences = "Experiences",
}

export enum ArrowKeyEnum {
  UP = "ArrowUp",
  DOWN = "ArrowDown",
  LEFT = "ArrowLeft",
  RIGHT = "ArrowRight",
}

export enum ErrorModalType {
  // air PF models
  PRICE_FREEZE_PURCHASE_SET_BOOK_PARAMS_FAILED = "price-freeze-purchase-set-book-params-failed",
  PRICE_FREEZE_PURCHASE_GENERIC_FAILED = "price-freeze-purchase-generic-failed",
  PRICE_FREEZE_PURCHASE_GENERATE_CUSTOM_OFFER_FAILED = "price-freeze-purchase-generate-custom-offer-failed",
  PRICE_FREEZE_PURCHASE_FROZEN_PRICE_HAS_INCREASED = "price-freeze-purchase-frozen-price-has-increased",
  PRICE_FREEZE_PURCHASE_FROZEN_PRICE_HAS_INCREASED_DURING_PAYMENT = "price-freeze-purchase-frozen-price-has-increased-during-payment",
  PRICE_FREEZE_PURCHASE_FROZEN_PRICE_HAS_DECREASED = "price-freeze-purchase-frozen-price-has-decreased",
  PRICE_FREEZE_PURCHASE_FROZEN_PRICE_HAS_DECREASED_DURING_PAYMENT = "price-freeze-purchase-frozen-price-has-decreased-during-payment",
  PRICE_FREEZE_PURCHASE_HAS_NO_AVAILABILITY = "price-freeze-purchase-has-no-availability",
  PRICE_FREEZE_EXERCISE_SET_BOOK_PARAMS_FAILED = "price-freeze-exercise-set-book-params-failed",
  PRICE_FREEZE_EXERCISE_GENERIC_FAILED = "price-freeze-exercise-generic-failed",
  PRICE_FREEZE_EXERCISE_SIMILAR_FLIGHTS_AND_REFUND = "price-freeze-exercise-similar-flights-and-refund",
  PRICE_FREEZE_EXERCISE_REFUND = "price-freeze-exercise-refund",
  PRICE_FREEZE_EXERCISE_PROVIDER_ERROR = "price-freeze-exercise-provider-error",
  // hotel PF models
  HOTEL_PRICE_FREEZE_EXERCISE_REFUND = "hotel-price-freeze-exercise-refund",
  HOTEL_PRICE_FREEZE_EXERCISE_GENERIC_FAILED = "hotel-price-freeze-exercise-generic-failed",
  HOTEL_PRICE_FREEZE_EXERCISE_LIKELY_FRAUD = "hotel-price-freeze-exercise-likely-fraud",
  // hotel error modals
  HOTEL_PRICE_DIFFERENCE = "hotel-price-difference",
  HOTEL_WITH_ADD_ONE_PRICE_DIFFERENCE = "hotel-with-add-on-price-difference",
  // other models
  FLIGHTS_PRICE_QUOTE_HAS_PRICE_DIFFERENCE = "flights-price-quote-has-price-difference",
  FLIGHTS_PRICE_QUOTE_WITH_ADD_ON_HAS_PRICE_DIFFERENCE = "flights-price-quote-with-add-on-has-price-difference",
  FLIGHTS_PRICE_QUOTE_HAS_NO_AVAILABILITY = "flights-price-quote-has-no-availability",
  FLIGHT_INACTIVITY = "flights-inactivity",
  PROVIDER_ERROR = "provider-error",
  NO_ADULT_PASSENGER = "no-adult-passenger",
  DUPLICATE_BOOKING = "duplicate-booking",
}

export interface ErrorTitles {
  agentSubtitle?: string;
  agentTitle?: string;
  icon?: string;
  /**
   * TODO: primaryButton and secondaryButton can be objects that have a text and onClickId,
   * so that we can map within BookingErrorModal with better extensibility
   */
  primaryButtonText?: string;
  secondaryButtonText?: string;
  // note: buttonLabel is added to overwritte its corresponding buttonText for text rendering only; it is not involved in any onClick logic
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
  subtitle?: string;
  mobileSubtitle?: string;
  /**
   * Note: it lets the consumer know that subtitle / mobileSubtitle needs to be injected as HTML
   */
  useHtml?: boolean;
  title: string;
  type?: ErrorModalType;
  showCloseButton?: boolean;
  closeButtonAction?: CloseButtonActionEnum;
}

export enum CloseButtonActionEnum {
  GoToHotelPriceFreezeOverview,
  HoteLoyaltyClose,
}

export enum ErrorType {
  BookError = "BookError",
  BookFailed = "BookFailed",
  CannotResume = "CannotResume",
  CaptureFailed = "CaptureFailed",
  CardDeclined = "CardDeclined",
  CheckInMinAgeNotMet = "CheckInMinimumAgeNotMet",
  DuplicateBooking = "DuplicateBooking",
  ExecutionTimeout = "ExecutionTimeout",
  FlightPaymentError = "FlightPaymentError",
  HardTimeout = "HardTimeout",
  Inactivity = "Inactivity",
  Incomplete = "Incomplete",
  InvalidGDSCharacter = "InvalidGDSCharacter",
  InvalidOffPoint = "InvalidOffPoint",
  InvalidPassengers = "InvalidPassengers",
  InvalidRequestError = "InvalidRequestError",
  InvalidScheduleChangeUA = "InvalidScheduleChangeUserAction",
  InvalidSegmentStatus = "InvalidSegmentStatus",
  MaxPriceIncreaseReached = "MaximumPriceIncreaseReached",
  MissingAirlineLocator = "MissingAirlineLocator",
  NoAvailability = "NoAvailability",
  NoProductsAvailable = "NoProductsAvailable",
  NoTicketlessResponse = "NoTicketlessResponse",
  NotApplicable = "N/A",
  NotTicketed = "NotTicketed",
  ParseError = "ParseError",
  PaymentError = "PaymentError",
  PrinterProfileAlreadyDesignated = "PrinterProfileAlreadyDesignated",
  ProviderError = "ProviderError",
  RMStepTimeout = "RemoteMachineStepTimeout",
  RMUncaughtException = "RemoteMachineUncaughtException",
  ReadRatesError = "ReadRatesError",
  RedemptionFailure = "RedemptionFailure",
  RequirementUnsupported = "RequirementUnsupported",
  SchedulingError = "SchedulingError",
  SegmentCancelledAfterTicketing = "SegmentCanceledAfterTicketing",
  SessionClosed = "SessionClosed",
  TicketReadError = "TicketReadError",
  Timeout = "Timeout",
  TipFetchFailed = "TipFetchFailed",
  TransientServiceError = "TransientServerError",
  UncategorizedError = "UncategorizedError",
  UncaughtException = "UncaughtException",
  UnconfirmedStatus = "UnconfirmedStatus",
  UnknownSabreAppError = "UnknownSabreAppError",
  VoidFailed = "VoidFailed",
  ZenDeskUserException = "ZenDeskUserException",
}

export type Error = ErrorType | HotelBookErrorType | PaymentErrorEnum | string;

export enum FlightShopStep {
  PricePrediction,
  ChooseDeparture,
  ChooseReturn,
  ReviewItinerary,
  FareDetails,
}

export interface IFlightGridFareMap {
  [sliceId: string]: IFlightGridFares;
}

export interface IFlightListData {
  fares: FlightFare[];
  slice: string;
}

export interface IFlightNumberFilter {
  airlineCode: AirlineCode;
  flightNumber: string;
}

export interface IReturnFlightsMap {
  [outgoingSliceId: string]: {
    /* value is trip summary id */
    [returnSliceId: string]: string;
  };
}

export interface IShoppedTrip {
  outgoingFareId: Maybe<string>;
  outgoingFareRating?: number;
  outgoingSliceId: Maybe<string>;
  returnFareId?: Maybe<string>;
  returnFareRating?: number;
  returnSliceId?: Maybe<string>;
  tripId: Maybe<string>;
}

export interface ITripDetails {
  [tripId: string]: TripDetails;
}

export interface ITripSummariesById {
  [key: string]: TripSummary;
}

export interface ITravelerStepErrors {
  phone?: boolean;
  email?: boolean;
  travelerSelect?: boolean;
  loyaltyNumber?: boolean;
  corpPrimaryTraveler?: boolean;
}

export interface Amount {
  amount: number;
  currency: string;
}

export interface DropdownAction {
  /**
   * If `true`, adds a 1px divider under this action item
   */
  divider?: boolean;
  label: string | JSX.Element;
  onClick: () => void;
}

export enum CustomEvents {
  editTravelersStateChange = "EditTravelersStateChange",
  showSupportModal = "ShowSupportModal",
}

export enum EditTravelerModalState {
  closed = 1,
  flight,
  ground,
  hotel,
  stays,
  trips,
  experiences,
  wallet,
}

export enum TravelerType {
  email = "email",
  phoneNumber = "phoneNumber",
}

export enum TravelerTypeEdit {
  email = "Email",
  phone = "Phone",
}

export interface TrackingProperties<T = JsObject> {
  properties?: T;
  encryptedProperties?: string;
}

export type PortalTypes = "consumer" | "corporate" | "admin";

export type Tenant = "capone" | "capone-corporate";

export type PolicyViolation = "TOO_EXPENSIVE" | "UNACCEPTABLE_FARE";

export interface PolicyCompliance {
  isInPolicy: boolean;
  reasons: PolicyViolation[];
}

export interface CorporateTravel {
  policyCompliance: PolicyCompliance;
}

export type FareClassValues =
  | "Basic"
  | "Economy"
  | "EconomyPlus"
  | "Business"
  | "First";
type CarTypes = "standard" | "intermediate" | "luxury";
export type StarRatings = 1 | 2 | 3 | 4 | 5;

export type ShortOrLongFlight = "Short" | "Long";

export type FlightPolicy = {
  duration: ShortOrLongFlight;
  maxPriceForRoundTrip?: FiatPrice;
  maxPriceForOneWay?: FiatPrice;
  allowedFareClasses: FareClassValues[];
};

export type HotelPolicy = {
  maxPricePerNight?: FiatPrice;
  allowedStarRatings: StarRatings[];
};

export type CarPolicy = {
  maxPricePerDay?: FiatPrice;
  allowedTypes: CarTypes[];
};

export type PolicyTier = {
  name: string;
  id: string;
  settings: {
    isApprovalRequired: boolean;
  };
  flights: {
    policies: FlightPolicy[];
    enableLongFlights: boolean;
    minHoursForLongFlight: number;
  };
  hotels: {
    policies: HotelPolicy[];
  };
  cars: {
    policies: CarPolicy[];
  };
};

export type ProductType = "hotel" | "flight" | "car" | "experience";

export interface MediaAsset {
  kind: MediaAssetKindEnum;
  url: string;
  description?: string;
}

export enum MediaAssetKindEnum {
  Unspecified = "Unspecified",
  Image = "Image",
}
