import React, { useEffect, useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { Box, Slide, Typography, useScrollTrigger } from "@material-ui/core";
import {
  Icon,
  IconName,
  B2BButton,
  useDeviceTypes,
  TravelWalletDetailsModal,
  getTravelOfferCardContent,
  ITravelOfferCardContent,
  ITravelWalletDetailsModalProps,
  CurrencyFormatters,
  TravelCreditHistoryModalContent,
  GenericDetailsCard,
  CfarImageDesktop,
  GenericDetailsCardTopSectionComponentEnum,
  GenericDetailsCardComponentEnum,
  ActionLink,
  CloseButtonIcon,
  isOfferHSPTiered,
} from "halifax";
import {
  LandingPageTakeovers,
  TakeoverEnum,
  CityNameResponse,
  PRICE_FREEZE_ID_QUERY_PARAM,
  TravelWalletTakeover,
  StatusTakeover,
  Transaction,
  SingleFlightExpiringPriceFreezeTakeover,
  SingleExpiringHotelPriceFreezeTakeover,
  TravelWalletCredit,
  RewardsAccount,
  DISCOUNT_PROMO_TERMS_CONDITIONS,
  REFUNDABLE_FARES_POINT_ONE,
  REFUNDABLE_FARES_TITLE,
  REFUNDABLE_FARES_LOCATION,
  REFUND_COVERAGE_PERCENTAGE_HARDCODE_80,
  REFUNDABLE_FARES_POINT_THREE,
  TERMS_COPY,
  CreditDetail,
  TravelWalletOffer,
  VIEWED_TRAVEL_OFFER,
  ENGAGED_CREDIT_CTA,
  VIEW_CREDIT_HISTORY,
  ENGAGED_OFFER_CTA,
  VIEWED_OFFER_BANNER,
  CorpSessionInfo,
} from "redmond";
import clsx from "clsx";
import dayjs from "dayjs";
import { fetchLandingPageTakeovers as landingPageTakeovers } from "../../api/v1/takeovers/fetchLandingPageTakeovers";
import { getCityName } from "../../api/v1/takeovers/getCityName";
import {
  ADDITIONAL_MOBILE_REDESIGN_HEADER_FOOTER_PATHS,
  PATH_FLIGHTS_FREEZE_OVERVIEW,
  PATH_HOTELS_FREEZE_OVERVIEW,
  PATH_TRAVEL_WALLET,
  PATH_TRIPS,
  PATH_TERMS_CFAR,
  PATH_TRAVEL_SALE,
} from "../../utils/urlPaths";
import config from "../../utils/config";
import * as constants from "./constants";
import "./styles.scss";
import { getPortalName, useUserContext } from "@capone/common";
import {
  ANNUAL_TRAVEL_CREDITS,
  AVAILABLE,
  CREDIT_OFFER_STACKING_V1,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  MOBILE_HOMESCREEN_REDESIGN_EXPERIMENT,
  MOBILE_HOMESCREEN_REDESIGN_V2,
  MOBILE_HOMESCREEN_REDESIGN_VARIANTS,
  TRAVEL_CREDIT_HISTORY_EXPERIMENT,
  TRAVEL_WALLET_CREDIT_EXPERIMENT,
  CFAR_DISCOUNT,
  CFAR_DISCOUNT_CONTROL,
  CFAR_DISCOUNT_VARIANTS,
  useExperiments,
  CFAR_DISCOUNT_10_PERCENT,
  CFAR_DISCOUNT_25_PERCENT,
  CFAR_DISCOUNT_75_PERCENT,
  CFAR_DISCOUNT_100_PERCENT,
  CfarDiscountVariantType,
  TRAVEL_SALE,
  TRAVEL_SALE_VARIANTS,
  CONTROL,
  TRAVEL_SALE_ACTIVE,
} from "../../context/experiments";
import { fetchTravelWalletCreditHistory } from "../../api/v1/takeovers/fetchTravelWalletCreditHistory";
import {
  VIEW_CREDIT_HISTORY_CTA,
  VIEW_CREDIT_HISTORY_DESCRIPTION,
} from "./constants";
import { trackEvent } from "../../api/v1/trackEvent";

interface IHomepageTakeoverProps extends RouteComponentProps {
  rewardsAccounts?: RewardsAccount[];
}

interface IHomepageTakeoverContent {
  subtitle: string | JSX.Element;
  ctaCopy?: string;
  ctaAction?: () => void;
  showTravelOfferModal?: boolean;
  modalVariant?: ITravelWalletDetailsModalProps["variant"];
  modalHeaderText?: string;
  travelOfferModalContent?: ITravelOfferCardContent;
  creditDetailsCardProps?: ITravelWalletDetailsModalProps["creditDetailsCardProps"];
  className?: string;
  iconName?: string;
  showDismiss?: boolean;
}

const HideOnScroll = ({
  children,
  disable,
}: {
  children: JSX.Element;
  disable: boolean;
}) => {
  const trigger = useScrollTrigger({ disableHysteresis: true });

  return (
    <Slide appear={false} direction="down" in={disable ? true : !trigger}>
      {children}
    </Slide>
  );
};

interface IDiscountPromoText {
  homescreenBanner: string;
  modalBanner: string;
  modalBody: string;
}
export const HomepageTakeover = (props: IHomepageTakeoverProps) => {
  const { history, rewardsAccounts } = props;
  const { matchesMobile } = useDeviceTypes();
  const [content, setContent] = useState<IHomepageTakeoverContent>();
  const [openRFModal, setOpenRFModal] = useState<boolean>(false);
  const [openTravelOfferModal, setOpenTravelOfferModal] = React.useState(false);
  const expState = useExperiments();
  const [creditsHistoryTransactions, setCreditsHistoryTransaction] = useState<
    Transaction[]
  >([]);
  const [creditHistoryModalContent, setCreditsHistoryModalContent] =
    useState<TravelCreditHistoryModalContent>();
  const [takeoverCredit, setTakeoverCredit] = React.useState<
    TravelWalletCredit | undefined
  >();
  const [hideTakeover, setHideTakeover] = React.useState(false);
  const [creditBreakdown, setCreditBreakdown] = React.useState<CreditDetail[]>(
    []
  );

  const [discountPromoText, setDiscountPromoText] =
    React.useState<IDiscountPromoText>({} as IDiscountPromoText);
  const [takeoverOffer, setTakeoverOffer] = React.useState<
    TravelWalletOffer | undefined
  >(undefined);
  const isTravelWalletCreditsExperiment =
    getExperimentVariant(
      expState.experiments,
      TRAVEL_WALLET_CREDIT_EXPERIMENT
    ) === AVAILABLE;

  const mobileHomeScreenVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    MOBILE_HOMESCREEN_REDESIGN_EXPERIMENT,
    MOBILE_HOMESCREEN_REDESIGN_VARIANTS
  );

  const isCreditAndOfferStackingExperimentV1 =
    getExperimentVariant(expState.experiments, CREDIT_OFFER_STACKING_V1) ===
    AVAILABLE;

  const isTravelCreditHistoryExperiment =
    getExperimentVariant(
      expState.experiments,
      TRAVEL_CREDIT_HISTORY_EXPERIMENT
    ) === AVAILABLE;

  const cfarDiscountVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    CFAR_DISCOUNT,
    CFAR_DISCOUNT_VARIANTS
  );
  const isAnnualTravelCreditsExperiment =
    getExperimentVariant(expState.experiments, ANNUAL_TRAVEL_CREDITS) ===
    AVAILABLE;

  const travelSalesEventVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    TRAVEL_SALE,
    TRAVEL_SALE_VARIANTS
  );

  const { sessionInfo } = useUserContext(config.TENANT);
  const isNonFinancialUser =
    (sessionInfo as CorpSessionInfo)?.corporateInfo?.cap1Role === "NFU";
  const CFAR_DISCOUNT_EXPIRATION_DATE_MILLIS =
    config.CFAR_DISCOUNT_EXPIRATION_DATE_MILLIS;
  const EXP_DATE = CFAR_DISCOUNT_EXPIRATION_DATE_MILLIS
    ? dayjs(parseInt(CFAR_DISCOUNT_EXPIRATION_DATE_MILLIS)).format(
        "MMMM D, YYYY"
      )
    : "";
  const getDiscountPromoText = (
    experiment: CfarDiscountVariantType
  ): IDiscountPromoText => {
    switch (experiment) {
      case CFAR_DISCOUNT_CONTROL:
        return { homescreenBanner: "", modalBanner: "", modalBody: "" };
      case CFAR_DISCOUNT_10_PERCENT:
        return {
          homescreenBanner: "10% off",
          modalBanner: "10% off limited-time promotion",
          modalBody: "10% off",
        };
      case CFAR_DISCOUNT_25_PERCENT:
        return {
          homescreenBanner: "25% off",
          modalBanner: "25% off limited-time promotion",
          modalBody: "25% off",
        };
      case CFAR_DISCOUNT_75_PERCENT:
        return {
          homescreenBanner: "75% off",
          modalBanner: "75% off limited-time promotion",
          modalBody: "75% off",
        };
      case CFAR_DISCOUNT_100_PERCENT:
        return {
          homescreenBanner: "Free limited-time promotion",
          modalBanner: "Free limited-time promotion",
          modalBody: "no additional fee",
        };
    }
  };

  const trackOfferEngagementEvent = (offer?: TravelWalletOffer) => {
    trackEvent({
      eventName: ENGAGED_OFFER_CTA,
      properties: {
        location: "homepage",
        entry_type: "banner",
        offer_name: offer ? undefined : "multiple",
        funnel: offer?.funnels.join(","),
      },
      encryptedProperties: offer
        ? [offer.trackingPropertiesV2?.encryptedProperties ?? ""]
        : [],
    });
  };

  const trackViewedOfferBannerEvent = (offer?: TravelWalletOffer) => {
    trackEvent({
      eventName: VIEWED_OFFER_BANNER,
      properties: {
        location: "homepage",
        offer_name: offer ? undefined : "multiple",
      },
      encryptedProperties: offer
        ? [offer.trackingPropertiesV2?.encryptedProperties ?? ""]
        : [],
    });
  };

  const discountContent = React.useMemo(
    () => (
      <div>
        Plan your trip worry-free. After selecting your flight, add the
        flexibility to cancel it for any reason for{" "}
        {cfarDiscountVariant === CFAR_DISCOUNT_100_PERCENT ? (
          <span className="cross-out">a fee</span>
        ) : null}{" "}
        <span className="green-text">{discountPromoText.modalBody}</span>{" "}
        {cfarDiscountVariant !== CFAR_DISCOUNT_100_PERCENT
          ? "the usual fee "
          : ""}
        (available for 1 booking per account on eligible flights
        {EXP_DATE && <> departing before {EXP_DATE}</>}).
      </div>
    ),
    [cfarDiscountVariant, discountPromoText]
  );

  useEffect(() => {
    if (expState.experiments.length === 0) {
      return;
    }

    fetchLandingPageTakeovers().then((response) => {
      if (
        expState !== undefined ||
        (response as LandingPageTakeovers).takeovers !== undefined ||
        (response as LandingPageTakeovers).walletTakeover !== undefined
      ) {
        const takeovers = (response as LandingPageTakeovers).takeovers;
        const walletTakeover = (response as LandingPageTakeovers)
          .walletTakeover as TravelWalletTakeover;

        const statusTakeover = (
          (response as LandingPageTakeovers).statusTakeover as StatusTakeover
        )?.message
          ? (response as LandingPageTakeovers).statusTakeover
          : undefined;
        const pfTakeover = takeovers?.length > 0 ? takeovers[0] : undefined;
        const walletTakeoverWithOffer =
          walletTakeover?.offerCount > 0 || !!walletTakeover?.credit
            ? walletTakeover
            : undefined;

        // note: there could be more types of takeovers other than just PF; when more of them are added,
        // this logic needs to be revisited
        const takeover =
          statusTakeover || pfTakeover || walletTakeoverWithOffer;

        const pfTakeovers = [
          TakeoverEnum.SingleExpiringPriceFreezeTakeover,
          TakeoverEnum.SingleExpiringHotelPriceFreezeTakeover,
          TakeoverEnum.MultipleExpiringHotelPriceFreezesTakeover,
          TakeoverEnum.MultipleExpiringPriceFreezesTakeover,
        ];
        // if the takeover is not PF related, then we use the cfar discount related banner
        if (
          cfarDiscountVariant !== CFAR_DISCOUNT_CONTROL &&
          takeover?.Takeover &&
          !pfTakeovers.includes(takeover.Takeover)
        ) {
          const discountPromoText: IDiscountPromoText =
            getDiscountPromoText(cfarDiscountVariant);

          setContent({
            subtitle: `<strong>${discountPromoText.homescreenBanner} | Add the flexibility to cancel your flight for any reason </strong> (on eligibile flights).`,
            className: "cfar-discount",
            iconName: "check-shield-blue",
            ctaCopy: "View Details",
            ctaAction: () => setOpenRFModal(true),
          });
          setDiscountPromoText(discountPromoText);
          return;
        }

        switch (takeover?.Takeover) {
          case TakeoverEnum.StatusTakeover:
            setContent({ subtitle: takeover.message });
            break;
          case TakeoverEnum.SingleExpiringPriceFreezeTakeover:
            setContent({
              subtitle: constants.SINGLE_EXPIRING_PF_SUBTITLE(
                takeover.destinationCity
              ),
              ctaCopy: constants.COMPLETE_BOOKING_NOW,
              ctaAction: () => {
                history.push({
                  pathname: PATH_FLIGHTS_FREEZE_OVERVIEW,
                  search: `?${PRICE_FREEZE_ID_QUERY_PARAM}=${takeover.priceFreezeId}`,
                });
              },
            });
            break;
          case TakeoverEnum.SingleExpiringHotelPriceFreezeTakeover:
            setContent({
              subtitle: constants.SINGLE_HOTEL_EXPIRING_PF_SUBTITLE(
                takeover.hotelName
              ),
              ctaCopy: constants.COMPLETE_BOOKING_NOW,
              ctaAction: () => {
                history.push({
                  pathname: PATH_HOTELS_FREEZE_OVERVIEW,
                  search: `?${PRICE_FREEZE_ID_QUERY_PARAM}=${takeover.priceFreezeId}`,
                });
              },
            });
            break;
          case TakeoverEnum.MultipleExpiringPriceFreezesTakeover:
          case TakeoverEnum.MultipleExpiringHotelPriceFreezesTakeover:
            setContent({
              subtitle: constants.MULTIPLE_EXPIRING_PFS_SUBTITLE,
              ctaCopy: constants.MY_TRIPS,
              ctaAction: () => {
                history.push({
                  pathname: PATH_TRIPS,
                  search: "?tripsFilter=Frozen%20Prices",
                });
              },
            });
            break;
          case TakeoverEnum.TravelWalletTakeover:
            if (travelSalesEventVariant === TRAVEL_SALE_ACTIVE) {
              setContent({
                subtitle: (
                  <>
                    Our first-ever travel sale has landed.{" "}
                    <Link
                      to={`${PATH_TRAVEL_SALE}?entryType=search_banner`}
                      target={matchesMobile ? undefined : "_blank"}
                      className="travel-sale-banner-link"
                    >
                      Explore destinations on sale
                    </Link>
                  </>
                ),
                iconName: "offer-tag",
                className: "travel-sale-takeover",
                showDismiss: true,
              });
            } else if (
              takeover.credit &&
              Math.abs(takeover.bestOffer?.amount.amount || 0) <
                Math.abs(takeover.credit.amount.amount)
            ) {
              setContent({
                subtitle: constants.CREDITS_SUBTITLE(takeover.credit.amount),
                showTravelOfferModal: true,
                iconName: "piggy-bank-icon",
                className: takeover.Takeover,
                modalVariant: "credit",
                showDismiss: true,
                modalHeaderText: constants.CREDITS_MODAL_HEADER_TEXT(
                  sessionInfo?.userInfo.firstName
                ),
                creditDetailsCardProps:
                  constants.CREDITS_MODAL_DETAILS_CARD_PROPS(
                    creditBreakdown,
                    sessionInfo?.userInfo.firstName,
                    takeover.credit.amount,
                    isCreditAndOfferStackingExperimentV1,
                    isNonFinancialUser
                  ),
              });
              if (
                isTravelCreditHistoryExperiment &&
                !!creditsHistoryTransactions.length
              ) {
                setCreditsHistoryModalContent({
                  header: constants.CREDIT_BALANCE_DESCRIPTION(
                    sessionInfo?.userInfo.firstName
                  ),
                  balanceText: constants.CREDIT_HISTORY_BALANCE_TEXT(
                    CurrencyFormatters.getSymbol(
                      takeover.credit.amount.currency
                    ),
                    CurrencyFormatters.get(
                      takeover.credit.amount.currency
                    ).format(
                      (takeover.credit.amount.amount &&
                        takeover.credit.amount.amount * -1) ||
                        0
                    )
                  ),
                  subHeader: constants.CREDIT_HISTORY_MODAL_SUBHEADER,
                  creditListHeader: constants.CREDIT_HISTORY_MODAL_LIST_HEADER,
                });
              }
              setTakeoverCredit(takeover.credit);
              setCreditBreakdown(takeover.creditBreakdown);
            } else if (takeover.offerCount > 1) {
              trackEvent({
                eventName: VIEWED_TRAVEL_OFFER,
                properties: {
                  location: "homepage-banner",
                },
                encryptedProperties: [
                  takeover.bestOffer?.trackingPropertiesV2
                    ?.encryptedProperties ?? "",
                ],
              });

              setContent({
                subtitle: constants.MULTIPLE_OFFER_SUBTITLE(
                  takeover.offerCount
                ),
                ctaCopy: constants.VIEW_TRAVEL_OFFERS,
                ctaAction: () => {
                  history.push({
                    pathname: PATH_TRAVEL_WALLET,
                    hash: "offers",
                  });
                  trackOfferEngagementEvent();
                },
                showDismiss: true,
                className: takeover.Takeover,
                iconName: "offer-tag",
              });

              trackViewedOfferBannerEvent();
            } else if (takeover.bestOffer) {
              const offerTakeoverContent = constants.BEST_OFFER_SUBTITLE(
                takeover.bestOffer
              );
              offerTakeoverContent &&
                setContent({
                  subtitle: offerTakeoverContent,
                  showTravelOfferModal: true,
                  travelOfferModalContent: getTravelOfferCardContent(
                    takeover.bestOffer,
                    isCreditAndOfferStackingExperimentV1
                  ),
                  className: takeover.Takeover,
                  iconName: "offer-tag",
                  modalVariant: "offer",
                  showDismiss: true,
                });
              setTakeoverOffer(takeover.bestOffer);

              trackViewedOfferBannerEvent(takeover.bestOffer);
            }
            break;
          default:
            break;
        }
      }
    });
  }, [
    isTravelWalletCreditsExperiment,
    creditsHistoryTransactions,
    isTravelCreditHistoryExperiment,
    cfarDiscountVariant,
  ]);

  useEffect(() => {
    if (isTravelWalletCreditsExperiment) {
      fetchTravelWalletCreditHistory().then((response) => {
        setCreditsHistoryTransaction(response.credits);
      });
    }
  }, [isTravelCreditHistoryExperiment]);

  if (
    !content ||
    (matchesMobile &&
      ADDITIONAL_MOBILE_REDESIGN_HEADER_FOOTER_PATHS.includes(
        history.location.pathname
      ) &&
      mobileHomeScreenVariant !== MOBILE_HOMESCREEN_REDESIGN_V2)
  ) {
    return null;
  }

  return hideTakeover ? null : (
    <>
      <HideOnScroll disable={matchesMobile}>
        <Box
          className={clsx("homepage-takeover-root", {
            mobile: matchesMobile,
            "redesign-v2":
              mobileHomeScreenVariant === MOBILE_HOMESCREEN_REDESIGN_V2,
          })}
        >
          <Box
            className={clsx("homepage-takeover-container", content.className, {
              mobile: matchesMobile,
            })}
          >
            {matchesMobile ? (
              <>
                {content.iconName ? (
                  <Icon name={content.iconName} className={content.iconName} />
                ) : (
                  <Icon name={IconName.BellWarning} className="bell-icon" />
                )}
                <Box className="content-section">
                  {typeof content.subtitle === "string" ? (
                    <Typography
                      className="subtitle"
                      variant="body1"
                      dangerouslySetInnerHTML={{
                        __html: content.subtitle,
                      }}
                    />
                  ) : (
                    <Typography className="subtitle" variant="body1">
                      {content.subtitle}
                    </Typography>
                  )}
                  {content.ctaAction && content.ctaCopy && (
                    <B2BButton
                      onClick={content.ctaAction}
                      className="homepage-takeover-cta"
                      variant="b2b"
                    >
                      <Typography className="cta-text" variant="body1">
                        {content.ctaCopy}
                      </Typography>
                    </B2BButton>
                  )}
                  {content.showTravelOfferModal && (
                    <TravelWalletDetailsModal
                      openModal={openTravelOfferModal}
                      setOpenModal={(isOpen: boolean) => {
                        if (isOpen) {
                          trackEvent({
                            eventName: ENGAGED_CREDIT_CTA,
                            properties: {},
                          });

                          if (takeoverOffer) {
                            trackOfferEngagementEvent(takeoverOffer);
                          }
                        }
                        setOpenTravelOfferModal(isOpen);
                      }}
                      modalHeaderText={content.modalHeaderText || "Header"}
                      offerCardProps={{
                        offerContent: content.travelOfferModalContent,
                        titleLabelText: takeoverOffer
                          ? isOfferHSPTiered(takeoverOffer?.descriptions)
                          : undefined,
                      }}
                      creditDetailsCardProps={{
                        cardContent: content.creditDetailsCardProps,
                      }}
                      variant={content.modalVariant}
                      modalCTACopy={
                        content.modalVariant === "offer"
                          ? constants.VIEW_OFFER_DETAILS
                          : constants.VIEW_DETAILS
                      }
                      termsType={matchesMobile ? "accordion" : "link"}
                      isMobile={matchesMobile}
                      showCreditHistory={
                        isTravelCreditHistoryExperiment &&
                        content.modalVariant === "credit" &&
                        !!creditHistoryModalContent &&
                        !!creditsHistoryTransactions.length
                          ? true
                          : false
                      }
                      travelCreditHistoryProps={
                        isTravelCreditHistoryExperiment &&
                        content.modalVariant === "credit" &&
                        !!creditHistoryModalContent &&
                        !!creditsHistoryTransactions.length
                          ? {
                              modalContentProps: creditHistoryModalContent,
                              viewHistoryCTAText: VIEW_CREDIT_HISTORY_CTA,
                              creditTransactionsHistory:
                                creditsHistoryTransactions,
                              viewCreditHistoryDescription:
                                VIEW_CREDIT_HISTORY_DESCRIPTION,
                              rewardsAccounts,
                              credit: takeoverCredit,
                              creditBreakdown,
                            }
                          : undefined
                      }
                      isAnnualTravelCreditsExperiment={
                        isAnnualTravelCreditsExperiment
                      }
                      offerFunnels={takeoverOffer?.funnels}
                      offerLocation={takeoverOffer?.descriptions[2]}
                      isTravelSale={travelSalesEventVariant !== CONTROL}
                      imageId={takeoverOffer?.imageId}
                      onViewCreditHistory={() =>
                        trackEvent({
                          eventName: VIEW_CREDIT_HISTORY,
                          properties: {},
                        })
                      }
                    />
                  )}
                </Box>
              </>
            ) : (
              <Box className="content-section">
                <div className="dummy-flex-element" />
                <Box className="icon-subtitle-container">
                  {content.iconName ? (
                    <Icon
                      name={content.iconName}
                      className={content.iconName}
                    />
                  ) : (
                    <Icon name={IconName.BellWarning} className="bell-icon" />
                  )}
                  {typeof content.subtitle === "string" ? (
                    <Typography
                      className="subtitle"
                      variant="body1"
                      dangerouslySetInnerHTML={{
                        __html: content.subtitle,
                      }}
                    />
                  ) : (
                    <Typography className="subtitle" variant="body1">
                      {content.subtitle}
                    </Typography>
                  )}
                </Box>
                {content.ctaAction && content.ctaCopy && (
                  <B2BButton
                    onClick={content.ctaAction}
                    className="homepage-takeover-cta"
                    variant="b2b"
                  >
                    <Typography className="cta-text" variant="body1">
                      {content.ctaCopy}
                    </Typography>
                  </B2BButton>
                )}
                {content.showTravelOfferModal && (
                  <TravelWalletDetailsModal
                    openModal={openTravelOfferModal}
                    setOpenModal={(isOpen: boolean) => {
                      if (isOpen) {
                        trackEvent({
                          eventName: ENGAGED_CREDIT_CTA,
                          properties: {},
                        });

                        trackOfferEngagementEvent(takeoverOffer);
                      }
                      setOpenTravelOfferModal(isOpen);
                    }}
                    modalHeaderText={content.modalHeaderText || "Header"}
                    offerCardProps={{
                      offerContent: content.travelOfferModalContent,
                      titleLabelText: takeoverOffer
                        ? isOfferHSPTiered(takeoverOffer?.descriptions)
                        : undefined,
                    }}
                    creditDetailsCardProps={{
                      cardContent: content.creditDetailsCardProps,
                    }}
                    variant={content.modalVariant}
                    modalCTACopy={
                      content.modalVariant === "offer"
                        ? constants.VIEW_OFFER_DETAILS
                        : constants.VIEW_DETAILS
                    }
                    showButton
                    termsType={matchesMobile ? "accordion" : "link"}
                    isMobile={matchesMobile}
                    showCreditHistory={
                      isTravelCreditHistoryExperiment &&
                      content.modalVariant === "credit" &&
                      !!creditHistoryModalContent &&
                      !!creditsHistoryTransactions.length
                        ? true
                        : false
                    }
                    travelCreditHistoryProps={
                      isTravelCreditHistoryExperiment &&
                      content.modalVariant === "credit" &&
                      !!creditHistoryModalContent &&
                      !!creditsHistoryTransactions.length
                        ? {
                            modalContentProps: creditHistoryModalContent,
                            viewHistoryCTAText: VIEW_CREDIT_HISTORY_CTA,
                            creditTransactionsHistory:
                              creditsHistoryTransactions,
                            viewCreditHistoryDescription:
                              VIEW_CREDIT_HISTORY_DESCRIPTION,
                            icon: IconName.PiggyBankCircleOutline,
                            rewardsAccounts,
                            credit: takeoverCredit,
                            creditBreakdown,
                          }
                        : undefined
                    }
                    isAnnualTravelCreditsExperiment={
                      isAnnualTravelCreditsExperiment
                    }
                    offerFunnels={takeoverOffer?.funnels}
                    offerLocation={takeoverOffer?.descriptions[2]}
                    isTravelSale={travelSalesEventVariant !== CONTROL}
                    imageId={takeoverOffer?.imageId}
                    onViewCreditHistory={() =>
                      trackEvent({
                        eventName: VIEW_CREDIT_HISTORY,
                        properties: {},
                      })
                    }
                  />
                )}
                {content.showDismiss ? (
                  <ActionLink
                    className="takeover-dismiss-button"
                    content={<CloseButtonIcon />}
                    label="Close"
                    onClick={() => setHideTakeover(true)}
                  />
                ) : null}
              </Box>
            )}
          </Box>
        </Box>
      </HideOnScroll>
      <GenericDetailsCard
        popupClassName="refundable-fare-generic-details-card"
        openModal={openRFModal}
        onClose={() => setOpenRFModal(false)}
        topBannerContent={{
          iconName: IconName.CheckShieldBlue,
          bannerText: `${discountPromoText.modalBanner}`,
          component: GenericDetailsCardComponentEnum.TopBanner,
        }}
        topContent={{
          image: CfarImageDesktop,
          location: REFUNDABLE_FARES_LOCATION,
          header: REFUNDABLE_FARES_TITLE,
          component:
            GenericDetailsCardTopSectionComponentEnum.HeaderWithImageBackground,
        }}
        mainContent={[
          {
            id: "cfar-dicount",
            content: discountContent,
            component: GenericDetailsCardComponentEnum.Custom,
          },
          {
            type: "secondary",
            component: GenericDetailsCardComponentEnum.GenericCopy,
            copy: REFUNDABLE_FARES_POINT_ONE({
              variant: "v1",
              useStrong: true,
              punctuation: ".",
            }),
            icon: IconName.NotAllowedSignBlue,
          },
          {
            type: "secondary",
            component: GenericDetailsCardComponentEnum.GenericCopy,
            copy: REFUND_COVERAGE_PERCENTAGE_HARDCODE_80,
            icon: IconName.MoneyCounterClockwiseBlue,
          },
          {
            type: "secondary",
            component: GenericDetailsCardComponentEnum.GenericCopy,
            copy: REFUNDABLE_FARES_POINT_THREE(getPortalName(), {
              punctuation: ".",
            }),
            icon: IconName.SimpleLaptopBlue,
          },
          {
            message: TERMS_COPY,
            component: GenericDetailsCardComponentEnum.ClickableLink,
            onClick: () => window.open(PATH_TERMS_CFAR, "_blank")?.focus(),
            position: "center",
          },
          {
            type: "secondary",
            component: GenericDetailsCardComponentEnum.GenericCopy,
            copy: DISCOUNT_PROMO_TERMS_CONDITIONS(getPortalName()),
          },
        ]}
        contentOnly={false}
        contentClassName="refundable-fare-details-modal"
      />
    </>
  );
};

const fetchLandingPageTakeovers = async () => {
  try {
    const takeovers = await landingPageTakeovers();
    // note: since we are only consuming the first takeover, findIndex is all we need
    const singleFlightExpiringPriceFreezeIndex = takeovers.takeovers.findIndex(
      (takeover) =>
        takeover.Takeover === TakeoverEnum.SingleExpiringPriceFreezeTakeover
    );

    const singleHotelExpiringPriceFreezeIndex = takeovers.takeovers.findIndex(
      (takeover) =>
        takeover.Takeover ===
        TakeoverEnum.SingleExpiringHotelPriceFreezeTakeover
    );

    // If there's a flight and hotel price freeze expiring, show it as multiple price freeze expiring
    if (
      singleFlightExpiringPriceFreezeIndex >= 0 &&
      singleHotelExpiringPriceFreezeIndex >= 0
    ) {
      takeovers.takeovers = [
        {
          Takeover: TakeoverEnum.MultipleExpiringPriceFreezesTakeover,
        },
      ];
    } else if (singleFlightExpiringPriceFreezeIndex >= 0) {
      const cityCode = (
        takeovers.takeovers[
          singleFlightExpiringPriceFreezeIndex
        ] as SingleFlightExpiringPriceFreezeTakeover
      ).destinationCity;
      let cityNameResponse: CityNameResponse = {};

      try {
        cityNameResponse = await getCityName({
          cityOrAirportCodes: [cityCode],
        });
      } catch (err) {
        console.log(err);
      }

      takeovers.takeovers[singleFlightExpiringPriceFreezeIndex] = {
        ...takeovers.takeovers[singleFlightExpiringPriceFreezeIndex],
        // note: failover to cityCode when /cityName fails
        destinationCity: cityNameResponse[cityCode] ?? cityCode,
      } as SingleFlightExpiringPriceFreezeTakeover;
    } else if (singleHotelExpiringPriceFreezeIndex >= 0) {
      takeovers.takeovers[singleHotelExpiringPriceFreezeIndex] = takeovers
        .takeovers[
        singleHotelExpiringPriceFreezeIndex
      ] as SingleExpiringHotelPriceFreezeTakeover;
    }

    return takeovers;
  } catch (err) {
    console.log(err);
    return [];
  }
};
