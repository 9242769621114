import { useMemo } from "react";
import dayjs from "dayjs";
import { getConfigTenant, isCaponeTenant } from "@capone/common";
import { useExperimentIsVariant } from "@capone/experiments";
import { CorpSessionInfo, PolicyTier, SessionInfo } from "redmond";

export const useShowPolicyBanner = (
  policies?: PolicyTier,
  sessionInfo?: SessionInfo | CorpSessionInfo
) => {
  const isPolicyImprovementEnabled = useExperimentIsVariant(
    "corp-policy-improvement",
    "available"
  );

  if (
    isCaponeTenant(getConfigTenant()) ||
    !sessionInfo ||
    !("corporateInfo" in sessionInfo)
  )
    return false;

  const isDefaultHotelPoilicy = useMemo(
    () => isDefaultHotelPolicySelected(policies),
    [policies]
  );
  const isDefaultCarPoilicy = useMemo(
    () => isDefaultCarPolicySelected(policies),
    [policies]
  );
  const isDefaultFlightPoilicy = useMemo(
    () => isDefaultFlightPolicySelected(policies),
    [policies]
  );

  // Check if business was created after experiment started
  const isNewBusiness = useMemo(() => {
    const experimentStartDate = dayjs("02-04-2025", "MM-DD-YYYY");
    const businessCreateTime = sessionInfo.corporateInfo?.businessCreatedAt;
    return (
      businessCreateTime &&
      dayjs(businessCreateTime).isAfter(experimentStartDate)
    );
  }, [sessionInfo]);

  const shouldHidePolicy =
    isPolicyImprovementEnabled &&
    isDefaultHotelPoilicy &&
    isDefaultCarPoilicy &&
    isDefaultFlightPoilicy &&
    isNewBusiness;

  return !shouldHidePolicy;
};

const isDefaultHotelPolicySelected = (policyTier?: PolicyTier): boolean => {
  return (
    !!policyTier &&
    policyTier.hotels.policies.every((policy) => {
      const isAllStarRatingsAllowed = policy.allowedStarRatings.length === 5;
      const noMaxPricePolicy = policy.maxPricePerNight === undefined;
      return isAllStarRatingsAllowed && noMaxPricePolicy;
    })
  );
};

const isDefaultCarPolicySelected = (policyTier?: PolicyTier): boolean => {
  return (
    !!policyTier &&
    policyTier.cars.policies.every((policy) => {
      const noMaxPricePolicy = policy.maxPricePerDay === undefined;
      return noMaxPricePolicy;
    })
  );
};

const isDefaultFlightPolicySelected = (policyTier?: PolicyTier): boolean => {
  return (
    !!policyTier &&
    policyTier.flights.enableLongFlights &&
    policyTier.flights.policies.every((policy) => {
      const noMaxPrice =
        policy.maxPriceForOneWay == undefined &&
        policy.maxPriceForRoundTrip == undefined;
      const allFareClassesIncluded = policy.allowedFareClasses.length == 5;
      return noMaxPrice && allFareClassesIncluded;
    })
  );
};
