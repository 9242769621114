import axios from "axios";
import { PortalAccessResponse } from "redmond";
import { portalAccessPrefixV1 } from "../../paths";

const ENDPOINT = `${portalAccessPrefixV1}/businessAuthorized`;

const fetchBusinessPortalAuthorized =
  async (): Promise<PortalAccessResponse> => {
    try {
      const resp = await axios.put(
        ENDPOINT,
        {},
        {
          withCredentials: true,
        }
      );
      return resp.data;
    } catch (e) {
      return {isAuthorized: false, rewardsAccounts: []};
    }
  };

export default fetchBusinessPortalAuthorized;
